<template>
  <EnterJobSurveyWidget
    @entry-type-change="onEntryClick"
  ></EnterJobSurveyWidget>
  <EnterJobDataByTraitsModal
    v-if="isLicensedDistIdReady"
    :entryType="entryType"
    :userRole="currentUser.role"
    :licensedDistributorId="licensedDistributorId"
    :licensedDistAssocId="0"
    :clientId="0"
    :folderId="0"
  ></EnterJobDataByTraitsModal>
  <EnterJobDataByPrintedVersionModal
    v-if="isLicensedDistIdReady"
    :entryType="entryType"
    :userRole="currentUser.role"
    :licensedDistributorId="licensedDistributorId"
    :licensedDistAssocId="0"
    :clientId="0"
    :folderId="0"
  ></EnterJobDataByPrintedVersionModal>
</template>

<script lang="ts">
import { defineComponent, onMounted, ref, computed, watch } from "vue";
import { useStore } from "vuex";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { Actions } from "@/store/enums/StoreEnums";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import EnterJobSurveyWidget from "@/components/widgets/EnterJobSurvey-Widget.vue";
import EnterJobDataByTraitsModal from "@/components/modals/wizards/EnterJobDataByTraitsModal.vue";
import EnterJobDataByPrintedVersionModal from "@/components/modals/wizards/EnterJobDataByPrintedVersionModal.vue";

export default defineComponent({
  name: "ld-enter-job-survey",
  components: {
    EnterJobSurveyWidget,
    EnterJobDataByTraitsModal,
    EnterJobDataByPrintedVersionModal,
  },
  setup() {
    const store = useStore();
    const currentUser = store.getters.currentUser;
    const entryType = ref<string>();
    const licensedDistributorId = computed(() => {
      return store.getters.getLicensedDistId;
    });
    const isLicensedDistIdReady = ref(false);

    onMounted(() => {
      setCurrentPageBreadcrumbs("Enter Survey Results", ["Surveys"]);
    });

    if (licensedDistributorId.value > 0) {
      isLicensedDistIdReady.value = true;
    } else {
      store
        .dispatch(Actions.GET_LICENSED_DISTRIBUTOR_ID, currentUser.id)
        .then(() => {
          //
        })
        .catch(() => {
          Swal.fire({
            text: store.getters.getErrors[0],
            icon: "error",
            buttonsStyling: false,
            confirmButtonText:
              "There was a problem retrieving licensed distributor id.",
            customClass: {
              confirmButton: "btn fw-bold btn-light-danger",
            },
          });
        });
    }

    function onEntryClick(value) {
      entryType.value = value;
    }

    watch(licensedDistributorId, () => {
      isLicensedDistIdReady.value = true;
    });

    return {
      currentUser,
      entryType,
      licensedDistributorId,
      isLicensedDistIdReady,
      onEntryClick,
    };
  },
});
</script>
